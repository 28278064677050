/*
    COOKIE COVER STYLING
    This is our pre-defined cookie cover styling.
 */

 .ddmcc {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity ease-in-out 300ms;
    z-index: 10;
    max-width: 100%;
    width: 42rem;
    max-width: 100%;
}

.ddmcc-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    min-height: 20rem;
    background-color: rgba(0, 0, 0, .5);
}

.ddmcc-content {
    padding: 3rem 1.5rem;

    @media screen and (min-width: 64rem) {
        padding: 3rem;
    }

    h2, p {
        text-align: center;
        color: #fff;
    }
}

.ddmcc-buttons {
    display: flex;
    justify-content: center;
}

.ddmcc-button-accept {
    background-color: #fff; /* Secondary color */
    color: #000; /* Primary color */

    &:hover {
        background-color: #000; /* Primary color */
        color: #fff; /* Secondary color */
    }
}

[data-html-snippet] {
    display: none;
}