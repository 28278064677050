/**
    DEFAULT COOKIE BYTE STYLING

    Here we define basic styles for e.g. buttons or checkboxes. You can
    reuse each or replace them with your own to match your site's design.
 */

.ddm {
    button {
        -webkit-appearance: button;
        cursor: pointer;

        &:focus {
            outline: 2px solid transparent;
            outline-offset: 2px;
        }
    }

    * {
        box-sizing: border-box;
    }
}

/* Buttons */
#ddmcm-button-all, .ddmcc-button-accept {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.375rem 1.25rem;

    font-size: 1rem;
    font-weight: 700;
    text-decoration: none;
    transition: all ease-in-out 300ms;
}

/* Checkboxes */
.ddmcm .checkbox input[type='checkbox'] {
    display: none;

    & + label {
        position: relative;
        display: block;
        padding-left: 2rem;

        cursor: pointer;
        user-select: none;
    }

    &:disabled + label {
        color: #a3a3a3; /* Greyed out */
    }

    & + label::before {
        display: block;
        width: 1.25rem;
        height: 1.25rem;
        position: absolute;
        top: 0.15rem;
        left: 0;

        border: 2px solid #000; /* Primary color */
        content: '';
        transition: all .12s, border-color .08s;
    }

    &:disabled + label::before {
        border-color: #a3a3a3; /* Greyed out */
    }

    &:checked + label::before {
        width: 0.75rem;
        top: 0.5rem;
        transform: rotate(45deg) translate(-50%, -50%);

        background-color: transparent;
        border-color: transparent;
        border-right-color: #000; /* Primary color */
        border-bottom-color: #000; /* Primary color */
        border-right-width: 2px;
        border-bottom-width: 2px;
    }

    &:checked:disabled + label::before {
        border-right-color: #a3a3a3; /* Greyed out */
        border-bottom-color: #a3a3a3; /* Greyed out */
    }
}

/** Adds some basic styling to the modal's content itself.
 *  Everything after this line can be changed without a doubt. */
.ddmcm-content, .ddmcc-content {

    h2, p {
        &:not(:last-child) {
            padding-bottom: 1rem;
        }
    }

    h2 {
        font-size: 1.125rem;
        line-height: 1.75rem;

        @media screen and (min-width: 64rem) {
            font-size: 1.25rem;
            line-height: 1.75rem;
        }
    }

    /** Let's play safe: H2 is sometimes used for enormously huge texts,
     * so we define our own size, everything else is up to the other styles. */
    .ddmcm-title {
        position: sticky;
        top: 0;
        padding: 1rem 0;
        background-color: white;
    }
}

.ddmcm-content {
    margin: 0 1.5rem 1.5rem 1.5rem;
}
