/*
    COOKIE MODAL STYLING

    This is our pre-defined cookie modal styling.

    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    !!! Warning to the creative people out there                             !!!
    !!! ````````````````````````````````````````                             !!!
    !!! In this stylesheet are some pretty useful categories, which deletion !!!
    !!! could make some of the settings in the control panel, well, useless. !!!
    !!! Drive safe and most importantly: be careful.                         !!!
    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */

/** This is the background layer, which fills the whole screen. */
.ddmcm {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity ease-in-out 300ms;
    pointer-events: none;
    z-index: 100;

    /** These categories add the CP option to select a visible background layer. */
    &.back-light {
        background: rgba(64, 64, 64, .3);
        backdrop-filter: saturate(180%) blur(3px);
        pointer-events: auto;
    }

    &.back-heavy {
        background: rgba(64, 64, 64, .6);
        backdrop-filter: saturate(180%) blur(3px);
        pointer-events: auto;
    }
}

/** The relative wrapper, prepping the modal to be placed somewhere. */
.ddmcm-wrapper {
    position: relative;
    height: 100vh;
}

/** Controls the placement of the modal itself. */
.ddmcm-box {
    /* Initially, the modal is centered on the screen */
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    max-width: 28rem;
    max-height: 90vh;
    transform: translate(-50%, -50%);
    overflow-x: hidden;
    overflow-y: auto;
    pointer-events: auto;

    background-color: #fff;
    box-shadow: 0 0px 15px 3px rgb(44 62 80 / 10%), 0 0 6px 0 rgb(44 62 80 / 10%);

    /* Make the modal a little wider on desktops */
    @media screen and (min-width: 64rem) {
        max-width: 34rem;
    }

    /* Position the modal as defined by the categories above tablets */
    @media screen and (min-width: 48rem) {
        &.top {
            top: 2rem;
            bottom: auto;
        }

        &.left {
            left: 2rem;
            right: auto;
        }

        &.right {
            right: 2rem;
            left: auto;
        }

        &.bottom {
            bottom: 2rem;
            top: auto;
        }

        &:not(.center) {
            transform: none;
        }

        &.center {
            &.left, &.right {
                transform: translateY(-50%);
            }

            &.top, &.bottom {
                transform: translateX(-50%);
            }
        }
    }
}

/** Accordion styling **/

.ddmcm-accordion-item {

    .ddmcm-accordion-toggle {
        cursor: default;
        display: flex;
        align-items: center;
        user-select: none;
    }

    .ddmcm-accordion-arrow {
        display: flex;
        width: 1rem;
        height: 1rem;
        margin-left: 0.3rem;
        transition: transform 150ms ease-in-out;
        /* Thanks to bootstrap icons for this cool chevron icon (name: chevron-down) */
        background: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22currentColor%22%20viewBox%3D%220%200%2016%2016%22%3E%0A%20%20%3Cpath%20fill-rule%3D%22evenodd%22%20d%3D%22M1.646%204.646a.5.5%200%200%201%20.708%200L8%2010.293l5.646-5.647a.5.5%200%200%201%20.708.708l-6%206a.5.5%200%200%201-.708%200l-6-6a.5.5%200%200%201%200-.708z%22%2F%3E%0A%3C%2Fsvg%3E") no-repeat;
    }

    &.has-content .ddmcm-accordion-toggle {
        cursor: pointer;
    }
}

.ddmcm-accordion-toggle input[type="checkbox"] {
    display: none;

    &:checked + .ddmcm-accordion-arrow {
        transform: rotate(180deg);
    }
}

.ddmcm-accordion-content {
    transition: 500ms all cubic-bezier(0.4, 0, 0.2, 1);
    overflow: hidden;
    height: 0;
    font-size: 0.9em;

    /* Add some spacing on top and bottom of the content wrapper */
    &:before, &:after {
        content: '';
        height: 10px;
        display: block;
    }

    /* Basic table styling, let's keep it compact and simple */
    table td {
        padding: 0.25rem 1rem 0.25rem 0;
        border: none;
        vertical-align: top;

        h2, p {
            margin-bottom: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }
}


/***********  Everything is safe to be edited from here  ***********/

/** Now it's time to align the checkboxes. */
.ddmcm-categories {
    margin-bottom: 1.5rem;

    .checkbox {
        display: flex;
        justify-content: space-between;

        @media screen and (min-width: 48rem) {
            display: block;
        }
    }

    .ddmcm-category {
        display: flex;

        &:not(:last-child) {
            margin-bottom: 0.5rem;
        }
    }
}

.ddmcm-buttons {
    display: flex;
    flex-direction: column;

    button:not(:last-of-type) {
        margin-bottom: 1rem;
    }

    @media screen and (min-width: 64rem) {
        display: block;

        button:not(:last-of-type) {
            margin-bottom: 0;
            margin-right: 0.5rem;
        }
    }
}

#ddmcm-button-all {
    background-color: #000;
    border: 2px solid #000; /* Primary color */
    color: #fff; /* Secondary color */

    &:hover {
        background-color: #fff; /* Secondary color */
        color: #000; /* Primary color */
    }
}

#ddmcm-button-selected {
    background: none;
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-decoration: none;
    transition: color ease-in-out 300ms;

    &:hover {
        color: #a3a3a3;
    }
}
